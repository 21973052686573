import React from 'react';

import Banner, { pageType } from '../components/global/banner/banner';
import Layout from '../components/global/layout/layout';
import NewsletterForm from '../components/newsletter/newsletterForm';
import { PageBase } from '../context/global-context';
import {
  NewsletterData,
  NewsletterLocalizationData,
} from '../types/newsletter';
import { PageContext } from '../types/page';
import { getLocales, LocaleEnum, useI18n } from '../utils/i18n';

type NewsletterContext = PageContext & {
  locale: LocaleEnum;
  slug: string;
};

const Newsletter: React.FC<{ pageContext: NewsletterContext }> = ({
  pageContext,
}) => {
  const { translations, locale, slug } = pageContext;

  const i18n = useI18n(translations);
  const locales = getLocales([locale]);
  const localizationsData: NewsletterLocalizationData[] = [];

  locales.forEach((l) => {
    const slug = i18n.t('newsletter.slug', l);

    localizationsData.push({
      attributes: {
        slug: slug ? slug : 'newsletter',
        locale: l,
      },
    });
  });

  const instance: NewsletterData = {
    attributes: {
      slug: slug,
      locale: locale,
      localizations: {
        data: localizationsData,
      },
    },
  };

  return (
    <PageBase translations={translations} instance={instance}>
      <Layout>
        <Banner
          pageType={pageType.page}
          bannerType="newsletter"
          title={i18n.t('newsletter.page_title')}
        />
        <main>
          <NewsletterForm />
        </main>
      </Layout>
    </PageBase>
  );
};

export default Newsletter;
