import { Formik } from 'formik';
import React, { useContext, useState } from 'react';
import { Col, FloatingLabel, Form, Row } from 'react-bootstrap';
import * as Yup from 'yup';

import GlobalContext from '../../context/global-context';
import { useLocaleFromRouter } from '../../hooks/useLocaleFromRouter';
import { sendEmail } from '../../utils/emails';
import { LocaleType } from '../../utils/i18n';
import CustomButton, {
  bsButtonTypes,
  buttonOptions,
} from '../global/customButton/customButton';
import CustomToast from '../global/customToast/customToast';
import Loading from '../global/loading/loading';

import './newsletterForm.scss';

const NewsletterForm: React.FC = () => {
  const { i18n } = useContext(GlobalContext);
  const currentLocale: LocaleType = useLocaleFromRouter();

  const [emailSentError, setEmailSentError] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  const [formSuccess, setFormSuccess] = useState<boolean | null>(null);

  const schema = Yup.object().shape({
    email: Yup.string()
      .email(i18n?.t('form.errors.invalid_email'))
      .required(i18n?.t('form.errors.required_email')),
  });

  type NewsletterFormValues = {
    firstname: string;
    lastname: string;
    email: string;
  };

  const initialValues: NewsletterFormValues = {
    firstname: '',
    lastname: '',
    email: '',
  };

  const handleSubmit =
    (values: NewsletterFormValues) =>
    async (e: { preventDefault: () => void }) => {
      e.preventDefault();

      // Already submitting
      if (submitting) {
        return;
      }

      setEmailSentError(false);
      setSubmitting(true);

      const success = await sendEmail('newsletter', values, currentLocale);

      setSubmitting(false);
      setEmailSentError(!success);
      if (success) setFormSuccess(true);
    };

  const renderForm = () => {
    return (
      <Col xs={12} md={8} className="form-section">
        <h3 className="title"> {i18n?.t('newsletter.title')} </h3>
        <div className="body">{i18n?.t('newsletter.description')}</div>
        <Formik
          validationSchema={schema}
          // eslint-disable-next-line @typescript-eslint/no-empty-function
          onSubmit={() => {}}
          initialValues={initialValues}
        >
          {({ handleChange, handleBlur, values, errors, touched }) => (
            <Form
              className="form"
              noValidate
              onSubmit={handleSubmit(values)}
              id="newsletter-form"
            >
              <Row>
                <Col xl={6}>
                  <FloatingLabel
                    controlId="validationFormikFirstname"
                    label={i18n?.t('form.labels.firstname')}
                  >
                    <Form.Control
                      type="text"
                      placeholder={i18n?.t('form.labels.firstname')}
                      name="firstname"
                      value={values.firstname}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </FloatingLabel>
                </Col>
                <Col xl={6}>
                  <FloatingLabel
                    controlId="validationFormikLastname"
                    label={i18n?.t('form.labels.lastname')}
                  >
                    <Form.Control
                      type="text"
                      placeholder={i18n?.t('form.labels.lastname')}
                      name="lastname"
                      value={values.lastname}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </FloatingLabel>
                </Col>
              </Row>
              <FloatingLabel
                controlId="validationFormikEmail"
                label={`${i18n?.t('form.labels.email')}*`}
                className="email"
              >
                <Form.Control
                  type="email"
                  placeholder={`${i18n?.t('form.labels.email')}*`}
                  name="email"
                  value={values.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isInvalid={!!errors.email}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.email}
                </Form.Control.Feedback>
              </FloatingLabel>

              <CustomButton
                type={bsButtonTypes.submit}
                style={buttonOptions.filled_light}
                className="cta"
                disabled={
                  Object.keys(touched).length === 0 ||
                  Object.keys(errors).length > 0
                }
              >
                {submitting && <Loading type="btn" />}
                {!submitting && i18n?.t('newsletter.form.cta')}
              </CustomButton>
              {emailSentError && (
                <div className="form-error-container">
                  {i18n?.t('newsletter.form.error')}
                </div>
              )}
            </Form>
          )}
        </Formik>
      </Col>
    );
  };

  return (
    <div className="newsletter-form">
      <Row>
        <Col xs={12} md={4} className="image" />
        {renderForm()}
      </Row>

      <CustomToast toast={formSuccess} setToast={setFormSuccess}>
        <span>{i18n?.t('newsletter.form.success')}</span>
      </CustomToast>
    </div>
  );
};

export default NewsletterForm;
